import useScript from "@charlietango/use-script";
import {
  Button,
  Callout,
  Card,
  CardFooter,
  Center,
  Expandable,
  H3,
  HStack,
  Icon,
  Input,
  P1,
  P2,
  Section,
  Select,
  SmallText,
  Spacer,
  useConfig,
} from "@mailbrew/uikit";
import { useCouponInfo } from "components/PaywallStateProvider";
import { getSavedSignupParameters } from "components/SignupParametersTracker";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shuffle } from "utils/array";
import * as yup from "yup";
import timezones from "../data/timezones";
import { appErrorSelector } from "../reducers/appReducer";
import { register } from "../reducers/authReducer";
import { roleIds, roleLabels } from "../utils/roles";
import stringifyTimezone from "../utils/stringifyTimezone";
import CheckboxRow from "./CheckboxRow";
import InfoButton from "./InfoButton";
import Pages from "./Pages";

var mapValues = require("lodash.mapvalues");

const timezoneOptions = timezones.map((tz) => tz.name);
const timezoneOptionNames = timezones.map((tz) => stringifyTimezone(tz, "GMT"));

export const SignupFormCard = (props) => {
  return (
    <Section width="64em" center>
      <Card inline w="500px" maxW="100%">
        <SignupForm {...props} />
      </Card>
      <Spacer size="l" />
    </Section>
  );
};

const SignupForm = (props) => {
  const { formInitialValues: formInitialValuesOverride, hidePasswordField } = props;

  const config = useConfig();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.loading);
  const globalErrors = useSelector((state) => state.app.errors);

  const [submitCount, setSubmitCount] = useState(0);

  const [inferredTimezone, setInferredTimezone] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [legalAccepted, setLegalAccepted] = useState(false);
  const router = useRouter();
  const twitterSignupId = router.query.tid;
  const signupMethod = twitterSignupId ? "twitter" : "email";

  /**
   * Sign Up Pagination
   */
  const STEP_MAIN = "step_main";
  const STEP_ADDITIONAL = "step_additional";
  const [step, setStep] = useState(STEP_MAIN);

  const formSchema = buildSignupFormSchema(!hidePasswordField);

  useEffect(() => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const inferredTimezoneVar = timezoneOptions.find((tz) => tz === browserTimezone);
    setInferredTimezone(inferredTimezoneVar);
    if (inferredTimezoneVar) setSelectedTimezone(inferredTimezoneVar);
  }, []);

  // initialize recaptcha
  useScript("https://www.google.com/recaptcha/api.js?render=6Lf1DOgUAAAAAKv3H2vmnsRkr0oze_u_PP8701MV");

  const { coupon, email: urlEmail, ref: refQueryParam } = router.query;

  const shuffledSignupSource = useMemo(() => {
    const others = [
      {
        id: "cant_remember",
        label: "Don't remember",
      },
      {
        id: "other",
        label: "Other",
        followUp: "Tell us more",
      },
    ];
    return shuffle(signupSources).concat(others);
  }, []);
  const signupSourcesIds = shuffledSignupSource.map((e) => e.id);
  const signupSourcesLabels = shuffledSignupSource.map((e) => e.label);
  const signupSourcesFollowUps = signupSources.reduce((res, source) => ({ ...res, [source.id]: source.followUp }), {});

  // url email has precedence over the form initial values (from social login)
  // this is needed to make the old invite system work
  if (urlEmail && formInitialValuesOverride && urlEmail.length > 0) {
    formInitialValuesOverride.email = urlEmail;
  }

  const handleSubmit = (formValues) => {
    const extraParameters = {
      ...getSavedSignupParameters(),
      coupon: coupon,
    };

    dispatch(
      register({
        ...formValues,
        timezone: selectedTimezone,
        ...extraParameters,
        twitterSignupId,
      })
    );
  };

  const formInitialValues = {
    first_name: "",
    last_name: "",
    email: urlEmail || "",
    username: "",
    password: "",
    role: "",
    source: (() => {
      if (["the_hustle", "typefully"].includes(refQueryParam)) {
        return refQueryParam;
      }
      if (typeof window !== "undefined") {
        const value = localStorage ? localStorage.getItem("mailbrew_signup_source") : null;
        if (value && value.length > 0) return value;
      }
      return "";
    })(),
    source_text: (() => {
      if (typeof window !== "undefined") {
        const value = localStorage ? localStorage.getItem("mailbrew_signup_source_text") : null;
        if (value && value.length > 0) return value;
      }
      return "";
    })(),
    ...formInitialValuesOverride,
  };

  // when formInitialValues is provided (usually thanks to twitter auth)
  // we flag the values as touched so the errors show immediately
  const initialTouched = formInitialValuesOverride ? mapValues(formInitialValues, () => true) : undefined;

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formSchema,
    onSubmit: handleSubmit,
    initialTouched: initialTouched,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (Object.keys(globalErrors).length > 0 || Object.keys(formik.errors).length > 0) {
      setStep(STEP_MAIN);
    }
  }, [globalErrors, submitCount, formik.errors]);

  function labelOrPlaceholder(value) {
    return signupMethod === "twitter" ? { label: value } : { placeholder: value };
  }

  const getBackendError = (key) => globalErrors[`signup:${key}`];

  function formFieldError(key, hideLocalValidationMessage) {
    const localValidationError = formik.touched[key] && formik.errors[key];
    const backendError = getBackendError(key);

    // backend error has precedence if present
    if (backendError) {
      return backendError;
    }

    if (localValidationError) {
      if (hideLocalValidationMessage) {
        // just signal there is an error with a red border
        return true;
      } else {
        // actually show the error
        return localValidationError;
      }
    }
  }

  function formFieldShowCheckmark(key) {
    return !formik.errors[key] && !getBackendError(key) && (formik.touched[key] || formik.values[key].length > 0);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Pages step={step}>
        <Pages.Page step={STEP_MAIN}>
          <H3 align="center" mb={2}>
            Hi there 👋
          </H3>
          <P1 align="center">We'll help you create a great daily digest.</P1>
          <P1 mb={3}></P1>
          <Input
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="email"
            id="username" // <- "username" as email for chrome password save
            name="email"
            {...labelOrPlaceholder("Email")}
            error={formFieldError("email", true)}
            icon={formFieldShowCheckmark("email") && "checkmarkCircle"}
            iconSide="right"
            iconColor={config.colors.success}
          />
          <Expandable expanded={formFieldShowCheckmark("email")}>
            {formFieldShowCheckmark("email") && <P2 mt={1}>We'll send your email digests to this address.</P2>}
          </Expandable>
          <Spacer size={4} />
          <Input
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="off"
            name="username"
            {...labelOrPlaceholder("Username")}
            error={formFieldError("username", true)}
            icon={formFieldShowCheckmark("username") && "checkmarkCircle"}
            iconSide="right"
            iconColor={config.colors.success}
          />
          {formFieldShowCheckmark("username") && (
            <HStack noWrap vAlign="center" mt={1.5}>
              <P2>Your newsletters address: {formik.values.username}@inbox.mailbrew.com</P2>
              <InfoButton title="Subscribe to other newsletters with this address and receive them in your digest." />
            </HStack>
          )}
          <Spacer size={4} />
          <Input
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="first_name"
            {...labelOrPlaceholder("First Name")}
            error={formFieldError("first_name", true)}
            icon={formFieldShowCheckmark("first_name") && "checkmarkCircle"}
            iconSide="right"
            iconColor={config.colors.success}
          />
          {(() => {
            if (!hidePasswordField) {
              return (
                <>
                  <Spacer size={4} />
                  <Input
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    {...labelOrPlaceholder("Password")}
                    error={formFieldError("password")}
                    icon={formFieldShowCheckmark("password") && "checkmarkCircle"}
                    iconSide="right"
                    iconColor={config.colors.success}
                  />
                </>
              );
            }
          })()}
          <Spacer size={4} />
          {!inferredTimezone ? (
            <Fragment>
              <Select
                name="timezone"
                label="Timezone"
                placeholder="Select a timezone"
                selectedOption={selectedTimezone}
                onSelect={(t) => {
                  setSelectedTimezone(t);
                }}
                options={timezoneOptions}
                optionsNames={timezoneOptionNames}
                error={globalErrors["signup:timezone"]}
              />
              <Spacer />
            </Fragment>
          ) : (
            <Spacer size={2} />
          )}
          <CheckboxRow
            initiallyChecked={legalAccepted}
            onChange={(checked) => {
              setLegalAccepted(checked);
            }}
          >
            Accept{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://mailbrew.com/app-terms">
              Terms of Service
            </a>{" "}
            and{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://mailbrew.com/app-privacy">
              Privacy Policy
            </a>
          </CheckboxRow>
          <Spacer size="s" />
          <SignupCouponBadge coupon={coupon} />
          <SignupFooter
            buttonTitle="Continue"
            buttonDisabled={!legalAccepted}
            buttonLoading={loading}
            buttonSubmit
            onButtonClick={() => setSubmitCount(submitCount + 1)}
          />
          {/* <SignupFooter
                  buttonTitle="Next"
                  buttonDisabled={!legalAccepted}
                  buttonLoading={loading}
                  onButtonClick={(e) => {
                    e.preventDefault();
                    setStep(STEP_ADDITIONAL);
                  }}
                  onBackClick={() => setStep(STEP_MAIN)}
                /> */}
        </Pages.Page>
        <Pages.Page step={STEP_ADDITIONAL}>
          <H3 mb={1}>Almost done!</H3>
          <P1 mb={3}>Optional questions that really help us to understand our users.</P1>
          <Select
            label="(Optional) What do you do?"
            placeholder="Select an option"
            selectedOption={formik.values.role}
            options={roleIds}
            optionsNames={roleLabels}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="role"
            error={(formik.touched["role"] && formik.errors["role"]) || globalErrors["signup:role"]}
          />
          <Spacer size={4} />
          <Select
            label="(Optional) How did you discover Mailbrew?"
            placeholder="Select an option"
            selectedOption={formik.values.source}
            options={signupSourcesIds}
            optionsNames={signupSourcesLabels}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="source"
            error={(formik.touched["source"] && formik.errors["source"]) || globalErrors["signup:source"]}
          />
          {signupSourcesFollowUps[formik.values.source] && (
            <Fragment>
              <Spacer size={4} />
              <Input
                placeholder={signupSourcesFollowUps[formik.values.source]}
                value={formik.values.source_text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="username" // <- don't change, needed for chrome autofill
                name="source_text"
                error={
                  (formik.touched["source_text"] && formik.errors["source_text"]) || globalErrors["signup:source_text"]
                }
              />
            </Fragment>
          )}
          <Spacer size="s" />
          <SignupCouponBadge coupon={coupon} />
          <SignupFooter
            buttonTitle="Continue"
            buttonDisabled={!legalAccepted}
            buttonLoading={loading}
            buttonSubmit
            showBack
            onBackClick={() => setStep(STEP_MAIN)}
            onButtonClick={() => setSubmitCount(submitCount + 1)}
          />
        </Pages.Page>
      </Pages>
    </form>
  );
};

const SignupFooter = ({
  showBack,
  onBackClick,
  buttonTitle,
  buttonDisabled,
  buttonLoading,
  buttonSubmit,
  onButtonClick,
}) => {
  const config = useConfig();
  const signupError = useSelector(appErrorSelector("signup"));

  return (
    <CardFooter inline>
      <HStack {...(showBack ? { align: "spaced" } : { align: "right", vAlign: "center", noWrap: true })}>
        {showBack && (
          <Icon name="chevronLeft" size="30px" strokeWidth={1.5} color={config.colors.c4} onClick={onBackClick} />
        )}
        <HStack align="right" vAlign="center" noWrap>
          {signupError && (
            <Center>
              <SmallText color={config.colors.error}>{signupError}</SmallText>
            </Center>
          )}
          <Button
            onClick={onButtonClick}
            disabled={buttonDisabled}
            loading={buttonLoading}
            submit={buttonSubmit}
            noStretch
          >
            {buttonTitle}
          </Button>
        </HStack>
      </HStack>
    </CardFooter>
  );
};

export const SignupCouponBadge = ({ coupon: couponId }) => {
  const coupon = useCouponInfo(couponId);
  const config = useConfig();

  if (!coupon) {
    return null;
  } else {
    const extendedFreeTrial = !!coupon["metadata.trial_length"];

    const message = extendedFreeTrial
      ? `${coupon["metadata.trial_length"]} days of extended free trial, then ${coupon.percent_off}% off when you upgrade`
      : `${coupon.percent_off}% off on all our pro plans`;

    return (
      <Fragment>
        <Spacer size="s" />
        <Callout icon="couponAltBold" color={config.colors.success} title={`${coupon.name}`} body={message}></Callout>
      </Fragment>
    );
  }
};

export function buildSignupFormSchema(isPasswordRequired) {
  const optionalFields = {};

  if (isPasswordRequired) {
    optionalFields.password = yup.string().required("Password is required").min(6).max(64);
  }

  return yup.object().shape({
    ...optionalFields,
    email: yup.string().email().required("Email is required"),
    first_name: yup.string().required("First Name is required"),
    username: yup
      .string()
      .min(3)
      .max(24)
      .required("Username is required")
      .matches(/^[a-zA-Z0-9_]+$/, "Username is invalid"),
    source_text: yup.string().max(256, "Please write a shorter text."),
  });
}

const signupSources = [
  {
    id: "friend",
    label: "Friend told me about it",
  },
  {
    id: "teammate",
    label: "Teammate told me about it",
  },
  {
    id: "google",
    label: "Google Search",
    followUp: "What were you searching?",
  },
  {
    id: "product_hunt",
    label: "Product Hunt",
  },
  {
    id: "public_brew",
    label: "Mailbrew user public brew",
  },
  {
    id: "newsletter",
    label: "Newsletter",
    followUp: "Which newsletter?",
  },
  {
    id: "podcast",
    label: "Podcast",
    followUp: "Which podcast?",
  },
  {
    id: "twitter",
    label: "Twitter",
  },
  {
    id: "reddit",
    label: "Reddit thread",
    followUp: "Which subreddit?",
  },
  {
    id: "follow_cofounder",
    label: "I follow the co-founders",
  },
  {
    id: "typefully",
    label: "Typefully",
  },
];

export default SignupForm;
